import React from 'react';
import { Container } from 'reactstrap';
import './style.css';


const Emptytwo = () => {
  return (
    <Container className="bg-light text-center" fluid={true} id="emptytwo-div">
    </Container>
  );
}

export default Emptytwo;