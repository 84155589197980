import React, { useState }  from 'react';
import { Link } from "react-router-dom";
import { Container, Modal } from 'reactstrap';
import { Icon } from 'semantic-ui-react';
import './style.css';

const Footer = (props) => {
    const {
        buttonLabel,
        className
      } = props;
    
      const [modal1, setModal1] = useState(false);
    
      const toggle1 = () => setModal1(!modal1);
    
  return (
    <Container id="last" fluid={true}>
        {/* <Row>
            <Col lg="6" md="12" sm="12" xs="12" className="text-left footer-one">
                <h2 className="footer-title">Contact</h2>
                <p>Somerset, NJ 08873</p>
                <p className="mt-4">Call: <a className="whatIsDifferent-a" href="tel:+18482198878">(848) 219-8878</a></p>
                <p>E-Mail: <a className="whatIsDifferent-a" href="mailto:mhrpatel085@gmail.com">mhrpatel085@gmail.com</a></p>
            </Col>
            <Col lg="4" md="12" sm="12" xs="12" className="text-left footer-two">
                <h2 className="footer-title">Links</h2>
                <p><a className="whatIsDifferent-a" href="#WhatIsDifferent">About</a></p>
                <p><a className="whatIsDifferent-a" href="#steps">Get Started</a></p>
                <p><a className="whatIsDifferent-a" href="#questions">FAQ</a></p>
                <p><a className="whatIsDifferent-a" href="#">Specialty</a></p>
                <p><a className="whatIsDifferent-a" href="#">Career</a></p>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12" className="text-left footer-three">
                <h2 className="footer-title">Social</h2>
                <Icon size="big" name="facebook official"/>
                <Icon size="big" name="linkedin"/> 
                <Icon size="big" name="instagram"/>
            </Col>
        </Row> */}
        <Container className="mt-5 text-center" fluid={true}>
                <a className="footer-one" href="https://github.com/Mhr85" target="_blank"><Icon size="big" name="github square"/></a>
                <a className="footer-one" href="https://www.linkedin.com/in/mihir-patel-596aa262/" target="_blank"><Icon size="big" name="linkedin"/></a> 
                {/* <a href=""></a><Icon size="big" name="facebook official"/>
                <a href=""></a><Icon size="big" name="instagram"/> */}
            <p>&copy; {new Date().getFullYear()}{" "} Mihir Patel</p>
            <a onClick={toggle1} className="resume-link">Credits</a>
            <Modal isOpen={modal1} toggle={toggle1} className="modal-Container"> 
                <a href="http://www.freepik.com">Brand Illustration: Designed by slidesgo / Freepik</a>
            </Modal>
            {/* <a className="footer-one" href="/">Home |</a>
            <a className="footer-one" href="#"> About |</a>
            <a className="footer-one" href="#"> Contact |</a>
            <a className="footer-one" href="#"> Portfolio |</a>
            <a className="footer-one" href="#"> Resources</a> */}
            {/* <p><a href="#">Privacy Policy</a> | <a href="#">Terms & Conditions</a> | <a href="#">HIPAA Policy</a></p> */}
        </Container>
    </Container>
  );
}

export default Footer;