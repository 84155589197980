import React from "react";
import books from "../../books.js";
import { Container, Table } from "reactstrap";
import "../Brand/style.css";



function EducationEntry(props) {
  return (
    <Container className='mt-5' id='books'>
      <div className='container-fluid'>
        <div className='text-center mt-5 pt-5'>
          <h1 className='mt-5 resume-font'>Education</h1>
        </div>
      </div>
      {/* <div className='container bg-white p-5  mt-5'> */}
        <Table className="porfolio-images p-2">
          <thead className="thead-light">
            <tr>
              <th>#</th>
              <th>University Education</th>
              <th>School</th>
              <th>Graduated</th>
            </tr>
          </thead>
          <tbody>
              <tr>
                <th scope='row'>1</th>
                <td>BBA to MBA</td>
                <td>Capella University</td>
                <td>Currently Enrolled</td>
              </tr>
              <tr>
                <th scope='row'>2</th>
                <td>Full Stack Web Developer</td>
                <td>Rutgers University</td>
                <td>Aug 2019</td>
              </tr>
              <tr>
                <th scope='row'>3</th>
                <td>BBA With Advance Financial Management</td>
                <td>Sardar Patel University</td>
                <td>May 2012</td>
              </tr>
          </tbody>

          <thead className="thead-light">
            <tr>
              <th>#</th>
              <th>Web Development Courses</th>
              <th>Source</th>
              <th>Completed</th>
            </tr>
          </thead>
          <tbody>
              <tr>
                <th scope='row'>1</th>
                <td>The Complete 2021 Web Development Bootcamp</td>
                <td>Udemy</td>
                <td>Currently Learning</td>
              </tr>
              <tr>
                <th scope='row'>2</th>
                <td>React Hooks and Redux</td>
                <td>Udemy</td>
                <td>02/28/2021</td>
              </tr>
              <tr>
                <th scope='row'>3</th>
                <td>Modern JavaScript</td>
                <td>Udemy</td>
                <td>02/05/2021</td>
              </tr>
              <tr>
                <th scope='row'>4</th>
                <td>Adobe Illustrator</td>
                <td>Udemy</td>
                <td>08/23/2019</td>
              </tr>
              <tr>
                <th scope='row'>5</th>
                <td>SVG Animation</td>
                <td>Udemy</td>
                <td>08/20/2019</td>
              </tr>
          </tbody>


          <thead className="thead-light">
            <tr>
              <th>#</th>
              <th>Sales Courses</th>
              <th>Source</th>
              <th>Completed</th>
            </tr>
          </thead>
          <tbody>
              <tr>
                <th scope='row'>1</th>
                <td>Mastering the Close</td>
                <td>Cardone University</td>
                <td>Currently Learning</td>
              </tr>
              <tr>
                <th scope='row'>2</th>
                <td>The Perfect Sales System</td>
                <td>Cardone University</td>
                <td>12/05/2020</td>
              </tr>
              <tr>
                <th scope='row'>3</th>
                <td>Understanding Buyer Psychology</td>
                <td>Cardone University</td>
                <td>06/28/2020</td>
              </tr>
              <tr>
                <th scope='row'>4</th>
                <td>Sales Fundamentals</td>
                <td>Cardone University</td>
                <td>05/03/2020</td>
              </tr>
          </tbody>
        </Table>
      {/* </div> */}
    </Container>
  );
}

export default EducationEntry;