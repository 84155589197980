import React from 'react';
import Nav from '../components/Nav/Index.jsx';
import Resume from '../components/Brand/Resume.jsx';
import Footer from '../components/Footer/index.js';
import '../App.css';

function Resume1 () {
  return (
    <div>
      <Nav/>
      <Resume/>
      <Footer />
    </div>
  );
}

export default Resume1;