import React from "react";
import Nav from "../components/Nav/Index.jsx";
import Footer from "../components/Footer/index.js";
import "../App.css";
import EducationEntry from "../components/Education/EducationEntry.jsx";

function Education() {
  return (
    <div>
      <Nav />
      <EducationEntry />
      <Footer />
    </div>
  );
}

export default Education;
