import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'semantic-ui-css/semantic.min.css';

// routing components
import Home from './pages/Home.jsx';
import Portfolio from './pages/Portfolio';
import About from './pages/About';
import Resume from './pages/Resume.jsx';
import Books from './pages/Books.jsx';
import Education from './pages/Education.jsx';
import Strengths from './pages/Strengths.jsx';



ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Home {...props} />} />
      <Route path="/portfolio" exact render={props => <Portfolio {...props} />} />
      <Route path="/about" exact render={props => <About {...props} />} />
      <Route path="/resume" exact={true} component={Resume} />
      <Route path="/books" exact={true} component={Books} />
      <Route path="/strengths" exact={true} component={Strengths} />
      <Route path="/education" exact={true} component={Education} />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
