const books = [
    {
      id: 1,
      title: "How to Lead: Wisdom from the World's Greatest CEOs, Founders, and Game Changers ",
      author: "David M. Rubenstein",
      completed: "Currently Reading"
    },  
    {
      id: 2,
      title: "Eat Smarter",
      author: "Shawn Stevenson",
      completed: "04/03/2021"
    },
    {
      id: 3,
      title: "JavaScript in 24 Hours",
      author: "Phil Ballard",
      completed: "02/03/2021"
    },
    {
      id: 4,
      title: "Trailblazer (the power of business as greatest platform for change)",
      author: "Marc Benioff",
      completed: "1/17/2021"
    },
    {
      id: 5,
      title: "Juran on leadership for quality",
      author: "J.M Juran",
      completed: "12/05/2020"
    },
    {
      id: 6,
      title: "The power of habit",
      author: "Charles Duhigg",
      completed: "10/25/2020"
    },
    {
      id: 7,
      title: "unshakeable: your financial freedom playbook",
      author: "Tony Robins",
      completed: "10/10/2020"
    },
    {
      id: 8,
      title: "The dichotomy of leadership",
      author: "Jocko Willink, Leif Babin",
      completed: "09/14/2020"
    },
    {
      id: 9,
      title: "Extreme ownership",
      author: "Jocko Willink, Leif Babin",
      completed: "08/19/2020"
    },
    {
      id: 10,
      title: "How to create wealth investing in real estate",
      author: "Grant Cardon",
      completed: "08/02/2020"
    },
    {
      id: 11,
      title: "Building your own Guthrie",
      author: "Dan Pena",
      completed: "07/22/2020"
    },
    {
      id: 12,
      title: "Qla's 50-billion-dollar man",
      author: "Dan Pena",
      completed: "06/24/2020"
    },
    {
      id: 13,
      title: "This is marketing",
      author: "Seth Godin",
      completed: "06/11/2020"
    },
    {
      id: 14,
      title: "When is the right time to sell my business?",
      author: "Richard Mowrey",
      completed: "05/28/2020"
    },
    {
      id: 15,
      title: "Social media marketing 2020",
      author: "Robert Miller",
      completed: "05/24/2020"
    },
    {
      id: 16,
      title: "Scaling up",
      author: "Verne Harnish",
      completed: "05/03/2020"
    },
    {
      id: 17,
      title: "Blue Ocean Strategy ",
      author: "W. Chan Kim, Renee Mauborgne",
      completed: "03/19/2020"
    },
    {
      id: 18,
      title: "How to read a financial report",
      author: "John, Tage Tracy",
      completed: "03/27/2020"
    },
    {
      id: 19,
      title: "Measure what matters",
      author: "John Doerr",
      completed: "02/03/2020"
    },
    {
      id: 20,
      title: "How to analyze & influence people",
      author: "Dr. Jermiah Bonn",
      completed: "01/22/2020"
    },
    {
      id: 21,
      title: "Rich Dad Poor Dad",
      author: "Robert Kiyosaki",
      completed: "01/07/2020"
    },
    {
      id: 22,
      title: "The compound effect",
      author: "Darren Hardy",
      completed: "12/21/2019"
    },
    {
      id: 23,
      title: "The ride of a lifetime",
      author: "Robert Iger",
      completed: "12/15/2019"
    },
    {
      id: 24,
      title: "It's the manager",
      author: "Jim Clifton, Jim Harter",
      completed: "12/01/2019"
    },
  ];
  
  export default books;