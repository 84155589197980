import React from 'react';
import { Container } from 'reactstrap';
import { Button, Header } from 'semantic-ui-react';
import './style.css';

import ResumeMihir from '../../assets/images/Mihir Patel _ Resume.pdf';


const Resume1 = () => {

  return (
    <Container>
      {/* Print Button */}
      <div className='container-fluid'>
        <div className='text-center mt-5 pt-5'>
          <Button id='switch-button' className='mt-5'>
            <Button.Content visible>
              <a href={ResumeMihir} target='_blank' className='resume_button'>
                Print
              </a>
            </Button.Content>
          </Button>
          {/* <a className="d-flex justify-content-center ui active button m-5" href="./images/Mihir_Patel _ Resume.pdf" target="blank">
              <Icon className="print icon"></Icon> Print
            </a> */}
        </div>
      </div>
      {/* Header */}
      <div className='container bg-white p-5 porfolio-images' id='resume'>
        <h1 className='header resume-font'>MIHIR PATEL</h1>
        <p className='font-italic resume-font'>
          A Honest, Dedicated & Ridiculously Hard-Working Leader and Web
          Developer
        </p>

        <div className='ui grid'>
          <div className='twelve wide column'>
            <p className='m-0 p-0'>
              <i className='map  icon resume-font'></i>21 Ari Dr, Somerset, NJ
              08873
            </p>
            <p className='m-0 p-0'>
              <i className='phone icon resume-font'></i>(848) 219-8878
            </p>
            <p className='m-0 p-0'>
              <i className='envelope icon resume-font'></i>mhrpatel085@gmail.com
            </p>
          </div>

          <div className='four wide column'>
            <p className='m-0 p-0 d-flex justify-content-end'>
              <i className='window maximize outline icon resume-font'></i>
              <a
                href='https://www.devmihir.com/'
                target='_blank'
                className='resume-link'>
                Website
              </a>
            </p>
            <p className='m-0 p-0 d-flex justify-content-end'>
              <i className='linkedin icon resume-font'></i>
              <a
                href='https://www.linkedin.com/in/mihir-patel-596aa262'
                target='_blank'
                className='resume-link'>
                Linkedin
              </a>
            </p>
            <p className='m-0 p-0 d-flex justify-content-end'>
              <i className='github icon resume-font'></i>
              <a
                href='https://github.com/Mhr85'
                target='_blank'
                className='resume-link'>
                Github
              </a>
            </p>
          </div>

          <hr className='resume-hr' />
        </div>
        {/* SUMMARY */}
        <h3 className='header resume-font text-center pt-2'>SUMMARY</h3>
        <hr className='resume-hr' />
        <p className='text-center'>
          A leader with an undeniable reputation for delivering the values and
          solutions that positively impact the business. With 5 years of
          experience providing vision and direction to staff while overseeing an
          operational budget of $3+ Million. Experienced in team building &
          leadership, finance,HR, operations, strategic planning & execution,
          Windows system administration and troubleshoot, and anything else that
          I can help with. Also a Web Developer & Designer skilled in JavaScript,
          React, Express, Node, Mongo/MySQL and Adobe Applications.
        </p>

        {/* EDUCATION */}
        <h3 className='header resume-font text-center pt-2'>EDUCATION</h3>
        <hr className='resume-hr' />

        <div className='ui grid'>
          <div className='twelve wide column'>
            <Header className='resume-font m-0 p-0' as='h6'>
              Rutgers University
            </Header>
            <p className='font-italic m-0 p-0'>Full Stack Web Development</p>
          </div>

          <div className='four wide column'>
            <Header
              className='resume-font m-0 p-0 d-flex justify-content-end'
              as='h6'>
              Graduated Aug 2019
            </Header>
          </div>
        </div>

        <div className='ui grid'>
          <div className='twelve wide column'>
            <Header className='resume-font m-0 p-0' as='h6'>
              Sardar Patel University
            </Header>
            <p className='font-italic m-0 p-0'>
              Bachelor’s in Business Administration with Advance Financial
              Management{" "}
            </p>
          </div>

          <div className='four wide column'>
            <Header
              className='resume-font m-0 p-0 d-flex justify-content-end'
              as='h6'>
              Graduated May 2012
            </Header>
          </div>
        </div>

        {/* WORK EXPERIANCE */}
        <h3 className='header resume-font text-center pt-2'>WORK EXPERIENCE</h3>
        <hr className='resume-hr' />

        <div className='ui grid'>
          <div className='twelve wide column'>
            <Header className='resume-font m-0 p-0' as='h6'>
              Ocean Breeze Pharmacy
            </Header>
            <p className='font-italic m-0 p-0'>Director of Operations</p>
          </div>

          <div className='four wide column'>
            <Header
              className='resume-font m-0 p-0 d-flex justify-content-end'
              as='h6'>
              Staten Island, NY
            </Header>
            <p className='font-italic m-0 p-0 d-flex justify-content-end'>
              Jan 2016 - Present
            </p>
          </div>
        </div>
        <Header class="resume-font m-0 p-0 d-flex m-2" as='h6'><u>Supervisory Role</u></Header>
        <ul class="pt-2">
          <li>Manage Accounting team of 3 including CPA, AR & AP Specialist.</li>
          <li>Manage IT Technician team of 4.</li>
          <li>Manage Stock & Maintenance team of 4.</li>
          <li>Set goals for performance and deadlines in alignment with company’s plans and vision.</li>
          <li>Organize workflow and ensure that employees understand their duties or delegated tasks</li>
          <li>Monitor employee productivity and provide constructive feedback and coaching</li>
          <li>Receive complaints and resolve problems.</li>
          <li>Prepare and submit performance reports.</li>
          <li>Decide on reward and promotion based on performance.</li>
          <li>Ensure adherence to legal and company policies and procedures and undertake disciplinary actions if the need arises.</li>
        </ul>
        <Header class="resume-font m-0 p-0 d-flex m-2" as='h6'><u>Additionla Responsibilities</u></Header>
        <ul className='pt-2'>
          <li>
            Initiate and implement innovative ideas to resolve operational issues.
          </li>
          <li>
            Gather and analyze data to ensure informed management decisions.
          </li>
          <li>
            Manage day to day operations while maintaining an allocated budget.
          </li>
          <li>Build HR Policies & Procedures, hiring and maintaining employee records, employee coaching, and employee retention.</li>
          <li>Manage Pharmacy licensing and compliance in all 50 States.</li>
          <li>Maintain compliance with Foreign LLC.</li>
          <li>Maintain pharmacy websites.</li>
          <li>Troubleshoot computer hardware/software, printer, and network issues.</li>
        </ul>
        {/* H & M INTERNATIONAL */}
        <div className='ui grid'>
          <div className='twelve wide column'>
            <Header className='resume-font m-0 p-0' as='h6'>
              H & M International Transportation, Inc.
            </Header>
            <p className='font-italic m-0 p-0'>Billing Clerk</p>
          </div>

          <div className='four wide column'>
            <Header
              className='resume-font m-0 p-0 d-flex justify-content-end'
              as='h6'>
              Iselin, NJ
            </Header>
            <p className='font-italic m-0 p-0 d-flex justify-content-end'>
              Jun 2015 - Jan 2016
            </p>
          </div>
        </div>

        <ul className='pt-2'>
          <li>
            Preparing and issuing billing invoices and claims for clients using
            AS400.
          </li>
          <li>
            Updating customer files with current bills, invoices, and contact
            information.
          </li>
          <li>Receiving and verifying carrier invoices.</li>
          <li>Correcting errors or questionable invoices for adjustment.</li>
        </ul>
        {/* 7 - ELEVEN */}
        <div className='ui grid'>
          <div className='twelve wide column'>
            <Header className='resume-font m-0 p-0' as='h6'>
              7-Eleven
            </Header>
            <p className='font-italic m-0 p-0'>Assistant Manager</p>
          </div>

          <div className='four wide column'>
            <Header
              className='resume-font m-0 p-0 d-flex justify-content-end'
              as='h6'>
              New Brunswick, NJ
            </Header>
            <p className='font-italic m-0 p-0 d-flex justify-content-end'>
              Jan 2013 - June 2015
            </p>
          </div>
        </div>

        <ul className='pt-2'>
          <li>
            Making sure financial objectives are met such as sales and expense
            management.
          </li>
          <li>Managing sales floor to ensure great customer service.</li>
          <li>
            Merchandising product flow, opening and closing of the store,
            training new employees.
          </li>
          <li>Forecast, order, and stock merchandise products.</li>
        </ul>
        {/* EXPERIANCE */}
        <h3 className='header resume-font text-center pt-2'>SKILLS</h3>
        <hr className='resume-hr' />
        {/* PROGRAMING LANGUAGES */}
        <div className='ui grid'>
          <div className='twelve wide column'>
            <Header className='resume-font m-0 p-0' as='h6'>
              Programming Languages/technologies
            </Header>
            <p className='font-italic m-0 p-0'>
              HTML, CSS, JavaScript, jQuery, MySQL, Sequelize, MongoDB,
              Mongoose, Firebase, Node, Express, React, bcrypt, Passport.js, CSS
              Animation
            </p>
          </div>
        </div>

        <div className='ui grid'>
          <div className='twelve wide column'>
            <Header className='resume-font m-0 p-0' as='h6'>
              Adobe Applications
            </Header>
            <p className='font-italic m-0 p-0'>
              Illustrator, Photoshop, Premier Pro, Animate, Audition, Spark
            </p>
          </div>
        </div>

        <div className='ui grid'>
          <div className='twelve wide column'>
            <Header className='resume-font m-0 p-0' as='h6'>
              Microsoft Office Applications
            </Header>
            <p className='font-italic m-0 p-0'>
              Office 365 Administration, Word, Excel, Powerpoint, Publisher,
              Teams, Staffhub
            </p>
          </div>
        </div>
        {/* PROJECTS */}
        <div className='ui grid'>
          <div className='twelve wide column'>
            <Header className='resume-font m-0 p-0' as='h6'>
              Projects
            </Header>
            <p className='font-italic m-0 p-0'>
              <a
                href='https://www.oceanbreezepharmacy.com/'
                className='resume-link'
                target='_blank'>
                www.oceanbreezepharmacy.com
              </a>
            </p>
            <p className='font-italic m-0 p-0'>
              <a
                href='https://ob-university.herokuapp.com/'
                className='resume-link'
                target='_blank'>
                ob-university.herokuapp.com
              </a>
            </p>
            <p className='font-italic m-0 p-0'>
              <a
                href='https://mhr85.github.io/simonGame/'
                className='resume-link'
                target='_blank'>
                mhr85.github.io/simonGame
              </a>
            </p>
            <p className='font-italic m-0 p-0'>
              <a
                href='http://pethappy.herokuapp.com/'
                className='resume-link'
                target='_blank'>
                pethappy.herokuapp.com
              </a>
            </p>
          </div>
        </div>
        {/* Resume Ending Div  */}
      </div>
    </Container>
  );
}

export default Resume1;