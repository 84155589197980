import React, { useState }  from 'react';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import { Button, Icon, Image } from 'semantic-ui-react';
import { useSpring, animated } from 'react-spring';

import StepOne from '../../assets/images/OBP.png';
import StepTwo from '../../assets/images/ob-university.png';
import StepThree from '../../assets/images/simongame.png';
import StepFour from '../../assets/images/pet-happy.png';

// import OBPVIDEO from '../../assets/images/Videos/OBU/OBU.mp4';
// import OBUVIDEO from '../../assets/images/Videos/OBU/OBU.mp4';
import './style.css';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`

const PortfolioBrand1 = () => {
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))

  const {
    buttonLabel,
    className
  } = props;

  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);

  const toggle1 = () => setModal1(!modal1);
  const toggle2 = () => setModal2(!modal2);
  const toggle3 = () => setModal3(!modal3);
  const toggle4 = () => setModal4(!modal4);

  return (
    <Container id="brand1" fluid={true}>
      <Row>
        <Col lg="6" md="6" sm="12" xs="12">
        <Image src= { StepOne } className="porfolio-images img-fluid" />
        {/* <Button id="switch-button" className="portfolio_button">
        <Icon name="play circle outline" />
        </Button> */}
        </Col>
        <Col lg="6" md="6" sm="12" xs="12" className="porfolio-images">
          <h1 className="skills">Ocean Breeze Pharmacy</h1>
          <p><span>Description: </span>
            Healthcare website built with React Framework.
          </p>
          <p><span>Technologies Used : </span>
            <Icon className="skills" size="large" name='react' /> REACT {' '}
            <Icon className="skills" size="large" name='css3 alternate' /> CSS3 {' '}
            <Icon className="skills" size="large" name='js square' /> JavaScript {' '}
            <Icon className="skills" size="large" name='sass' /> SASS {' '}
          </p>
          <Button id="switch-button" className="mr-2">
              <Button.Content visible>
                <a href="https://www.oceanbreezepharmacy.com/" target="_blank" className="resume_button">
                  Website
                </a>
              </Button.Content>
          </Button>

          <Button className="resume_button mr-2" onClick={toggle1} id="switch-button">Video</Button>
          <Modal isOpen={modal1} toggle={toggle1} className="modal-Container"> 
            <ModalBody className="responsive-iframe embed-responsive embed-responsive-16by9">
            <iframe src="https://player.vimeo.com/video/495865846"  allowFullScreen className="responsive-iframe embed-responsive-item"></iframe>
            </ModalBody>
          </Modal>
        </Col>
      </Row>

      <Row>
        <Col lg="6" md="6" sm="12" xs="12">
        <Image src= { StepTwo } className="img-fluid" className="porfolio-images" />
        </Col>
        <Col lg="6" md="6" sm="12" xs="12" className="porfolio-images">
          <h1 className="skills">OB-University</h1>
          <p><span>Description: </span>
            Employee training website built with MySQL-Express-Handlebar-Node.js.
          </p>
          <p><span>Technologies Used : </span>
            <Icon className="skills" size="large" name='html5' /> HTML {' '}
            <Icon className="skills" size="large" name='css3 alternate' /> CSS3 {' '}
            <Icon className="skills" size="large" name='js square' /> JavaScript {' '}
            <Icon className="skills" size="large" name='code' /> Handlebar {' '}
            <Icon className="skills" size="large" name='server' /> Express {' '}
            <Icon className="skills" size="large" name='node' /> Node {' '}
            <Icon className="skills" size="large" name='database' /> MySQL {' '}
          </p>
          <Button id="switch-button" className="mr-2">
              <Button.Content visible>
                <a href="https://ob-university.herokuapp.com/" target="_blank" className="resume_button">
                  Website
                </a>
              </Button.Content>
          </Button>

          <Button className="resume_button mr-2" onClick={toggle2} id="switch-button">Video</Button>
          <Modal isOpen={modal2} toggle={toggle2} className="modal-Container"> 
            <ModalBody className="responsive-iframe embed-responsive embed-responsive-16by9">
            <iframe src="https://player.vimeo.com/video/495866469"  allowFullScreen className="responsive-iframe embed-responsive-item"></iframe>
            </ModalBody>
          </Modal>
        </Col>
      </Row>

      <Row>
        <Col lg="6" md="6" sm="12" xs="12">
        <Image src= { StepThree } className="img-fluid" className="porfolio-images" />
        </Col>
        <Col lg="6" md="6" sm="12" xs="12" className="porfolio-images">
          <h1 className="skills">Simon Game</h1>
          <p><span>Description: </span>
            Simon Game built with HTML, CSS, JavaScript.
          </p>
          <p><span>Technologies Used : </span>
            <Icon className="skills" size="large" name='html5' /> HTML {' '}
            <Icon className="skills" size="large" name='css3 alternate' /> CSS3 {' '}
            <Icon className="skills" size="large" name='js square' /> JavaScript {' '}
          </p>
          <Button id="switch-button" className="mr-2">
              <Button.Content visible>
                <a href="https://mhr85.github.io/simonGame/" target="_blank" className="resume_button">
                  Website
                </a>
              </Button.Content>
          </Button>

          <Button id="switch-button" className="mr-2">
              <Button.Content visible>
                <a href="https://github.com/Mhr85/simonGame" target="_blank" className="resume_button">
                  Github Code
                </a>
              </Button.Content>
          </Button>

          <Button className="resume_button mr-2" onClick={toggle3} id="switch-button">Video</Button>
          <Modal isOpen={modal3} toggle={toggle3} className="modal-Container"> 
            <ModalBody className="responsive-iframe embed-responsive embed-responsive-16by9">
            <iframe src="https://player.vimeo.com/video/495878343"  allowFullScreen className="responsive-iframe embed-responsive-item"></iframe>
            </ModalBody>
          </Modal>
        </Col>
      </Row>

      <Row>
        <Col lg="6" md="6" sm="12" xs="12">
        <Image src= { StepFour } className="img-fluid" className="porfolio-images" />

        </Col>
        <Col lg="6" md="6" sm="12" xs="12" className="porfolio-images">
          <h1 className="skills">Pet Happy</h1>
          <p><span>Description: </span>
            Pet Adoption website built with 
          </p>
          <p><span>Technologies Used : </span>
            <Icon className="skills" size="large" name='html5' /> HTML {' '}
            <Icon className="skills" size="large" name='css3 alternate' /> CSS3 {' '}
            <Icon className="skills" size="large" name='js square' /> JavaScript {' '}
            <Icon className="skills" size="large" name='server' /> Express {' '}
            <Icon className="skills" size="large" name='node' /> Node {' '}
            <Icon className="skills" size="large" name='database' /> MySQL {' '}
          </p>
          <Button id="switch-button" className="mr-2">
              <Button.Content visible>
                <a href="http://pethappy.herokuapp.com/" target="_blank" className="resume_button">
                  Website
                </a>
              </Button.Content>
          </Button>

          <Button id="switch-button" className="mr-2">
              <Button.Content visible>
                <a href="https://github.com/estomm01/Project-2-" target="_blank" className="resume_button">
                  Github Code
                </a>
              </Button.Content>
          </Button>

          <Button className="resume_button mr-2" onClick={toggle4} id="switch-button">Video</Button>
          <Modal isOpen={modal4} toggle={toggle4} className="modal-Container"> 
            <ModalBody className="responsive-iframe embed-responsive embed-responsive-16by9">
            <iframe src="https://player.vimeo.com/video/495901318"  allowFullScreen className="responsive-iframe embed-responsive-item"></iframe>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}

export default PortfolioBrand1;