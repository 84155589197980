import React from "react";
import books from "../../books.js";
import { Container, Table } from "reactstrap";
import "../Brand/style.css";

import { Button, Header } from "semantic-ui-react";

import ResumeMihir from "../../assets/images/Mihir Patel _ Resume.pdf";

function BookEntry(props) {
  return (
    <Container className='mt-5' id='books'>
      <div className='container-fluid'>
        <div className='text-center mt-5 pt-5'>
          <h1 className='mt-5 resume-font'>List of Books Read</h1>
        </div>
      </div>
      <div className='container bg-white p-5 porfolio-images mt-5'>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Author</th>
              <th>Completed</th>
            </tr>
          </thead>
          <tbody>
            {books.map((bookList) => (
              <tr>
                <th scope='row'>{bookList.id}</th>
                <td>{bookList.title}</td>
                <td>{bookList.author}</td>
                <td>{bookList.completed}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
}

export default BookEntry;
