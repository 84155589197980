import React from 'react';
import Nav from '../components/Nav/Index.jsx';
import About from '../components/Brand/Aboutme.jsx';
import Footer from '../components/Footer/index.js';
import '../App.css';

function Aboutme () {
  return (
    <div>
      <Nav/>
      <About/>
      <Footer />
    </div>
  );
}

export default Aboutme;