import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  // NavLink,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  NavbarText
} from 'reactstrap';
import { Icon, Header } from 'semantic-ui-react'
import LOGO from '../../assets/images/Logo_MP.png';
import './style.css';

const Example = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar className="fixed-top" expand="md" id="nav-style" color="faded" light>
        <NavbarBrand href="/"><img className="img-fluid, logo" src= { LOGO } alt="Ocean Breeze Pharmacy"></img></NavbarBrand>
        {/* <Icon name="sidebar" onClick={toggle} /> */}
        <NavbarToggler  onClick={toggle} id="nav-toggle"/>
        <Collapse size="huge" isOpen={isOpen} navbar>
          <Nav className="mr-auto nav-font" navbar>
            <NavItem className="mb-2">
              <Header as="h6"><Link className="nav-font" to="/">Home</Link></Header>
            </NavItem>
            <NavItem className="mb-2">
              <Header as="h6"><Link className="nav-font" to="/about">About Me</Link></Header>
            </NavItem>
            <NavItem className="mb-2">
              <Header as="h6"><Link className="nav-font" to="/strengths">My Strengths</Link></Header>
            </NavItem>
            <NavItem className="mb-2">
              <Header as="h6"><Link className="nav-font" to="/Portfolio">Portfolio</Link></Header>
            </NavItem>
            <NavItem className="mb-2">
              <Header as="h6"><Link className="nav-font" to="/books">Books Read</Link></Header>
            </NavItem>
            <NavItem className="mb-2">
              <Header as="h6"><Link className="nav-font" to="/education">Education</Link></Header>
            </NavItem>
            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret className="nav-font">
                <Link className="nav-font" to="/Portfolio">Portfolio</Link>
              </DropdownToggle>
              <DropdownMenu right className="nav-font">
                <DropdownItem className="nav-font">
                  <Link className="nav-font" to="/Portfolio1">Web Development</Link>
                </DropdownItem>
                <DropdownItem className="nav-font">
                  Branding
                </DropdownItem>
                <DropdownItem className="nav-font">
                  Video Production
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          </Nav>
          <NavbarText className="nav-phone"><Icon name='call' />Phone:<a href="tel:8482198878" className="nav-font"> (848) 219-8878</a></NavbarText>
          <NavbarText className="mnav-phone"><Icon name='mail' />Email:<a href="mailto:mhrpatel085@gmail.com" className="nav-font"> mhrpatel085@gmail.com</a></NavbarText>
        </Collapse>

      </Navbar>
    </div>
  );
}

export default Example;