import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from './pages/Home.jsx';
import Portfolio from './pages/Portfolio.jsx';
import About from './pages/About.jsx';
import Resume from './pages/Resume.jsx';
import Books from './pages/Books.jsx';
import Education from './pages/Education.jsx';
import Strengths from './pages/Strengths.jsx';

import './App.css';


function App() {
  return (
    <Router>
      <Route path="/" exact={true} component={Home} />
      <Route path="/portfolio" exact={true} component={Portfolio} />
      <Route path="/about" exact={true} component={About} />
      <Route path="/resume" exact={true} component={Resume} />
      <Route path="/strengths" exact={true} component={Strengths} />
      <Route path="/books" exact={true} component={Books} />
      <Route path="/education" exact={true} component={Education} />
    </Router>

    // <div>
    //   <Nav/>
    //   <Brand/>
    //   <Accreditation/>
    //   <Empty />
    //   <Emptytwo />
    //   <Steps />

    //   {/* <WhatIsDifferent /> */}
    //   {/* <WhatWeDo /> */}
    //   {/* <HIPAA /> */}
    //   {/* <Questions /> */}
    //   <Footer />
    // </div>
  );
}

export default App;
