import React from 'react';
import Nav from '../components/Nav/Index.jsx';
import Brand from '../components/Brand/Index.jsx';
import Accreditation from '../components/Accreditation/index.js';
import Steps from '../components/Steps/Index.jsx';
import WhatIsDifferent from '../components/WhatIsDifferent/index.js';
import Questions from '../components/Questions/index.js';
import Footer from '../components/Footer/index.js';
import Empty from '../components/Empty/index.js';
import Emptytwo from '../components/Emptytwo/index.js';
import HIPAA from '../components/HIPAA/index.js';
import '../App.css';

function App() {
  return (
    <div>
      <Nav/>
      <Brand/>
      {/* <Accreditation/> */}
      <Empty />
      <Emptytwo />
      <Steps />

      {/* <WhatIsDifferent /> */}
      {/* <WhatWeDo /> */}
      {/* <HIPAA /> */}
      {/* <Questions /> */}
      <Footer />
    </div>
  );
}

export default App;
