import React, { Link } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Card, Button, Image } from 'semantic-ui-react';
import Fade from 'react-reveal/Fade'; 
import StepOne from '../../assets/images/OBP.png';
import StepTwo from '../../assets/images/ob-university.png';
import StepThree from '../../assets/images/simongame.png';
import StepFour from '../../assets/images/pet-happy.png';
import './style.css';



const Steps = () => {  
  return (
    <Container className="text-center" fluid={true} id="steps">
        <h1 className="steps-title font-weight-bold">PORTFOLIO</h1>
        
        <Row>
            <Col lg="6" md="6" sm="12" xs="12" className="d-flex justify-content-center mt-5">
            <Fade top cascade duration={1000}>
                {/* <Card className="step-card"> */}
                    
                    <a href="https://www.oceanbreezepharmacy.com/" className="footer-one" target="_blank">
                        <Image src= { StepOne } className="porfolio-images img-fluid img-fluid" />
                    </a>
                    {/* <Card.Content>
                    <Card.Header className="steps-card-title"><a href="https://www.oceanbreezepharmacy.com/" className="footer-one" target="_blank">Ocean Breeze Pharmacy</a></Card.Header>
                    <Card.Description>
                        
                    </Card.Description>
                    </Card.Content> */}
                    {/* <Card.Content extra> */}
                    {/* Github Code is private  */}
                        {/* <Button.Group>
                            <Button id="switch-button">
                                <Button.Content visible>
                                    <a href="https://mhr85.github.io/MihirResume/" target="_blank" className="resume_button">
                                    Github Code is private 
                                    </a>
                                </Button.Content>
                            </Button>
                        </Button.Group> */}
                    {/* </Card.Content> */}
                {/* </Card> */}
            </Fade>
            </Col>

            <Col lg="6" md="6" sm="12" xs="12" className="d-flex justify-content-center mt-5">
            <Fade top cascade duration={1000} delay={1500}>
                {/* <Card className="step-card"> */}
                <a href="https://ob-university.herokuapp.com/" className="footer-one" target="_blank">
                <Image src= { StepTwo } className="porfolio-images img-fluid img-fluid"/>
                </a>
                    {/* <Card.Content>
                    <Card.Header className="steps-card-title"><a href="https://ob-university.herokuapp.com/" className="footer-one" target="_blank">OB-University</a></Card.Header>
                    <Card.Description>
                    </Card.Description>
                    </Card.Content>
                    <Card.Content extra> */}
                    {/* Github Code is private */}
                        {/* <Button.Group>
                            <Button id="switch-button">
                                <Button.Content visible>
                                    <a href="https://mhr85.github.io/MihirResume/" target="_blank">
                                    Resume
                                    </a>
                                </Button.Content>
                            </Button>
                        </Button.Group> */}
                    {/* </Card.Content> */}
                {/* </Card> */}
            </Fade>
            </Col>

            <Col lg="6" md="6" sm="12" xs="12" className="d-flex justify-content-center mt-5">
            <Fade top cascade duration={1000} delay={2000}>
                {/* <Card className="step-card"> */}
                <a href="https://mhr85.github.io/simonGame/" className="footer-one" target="_blank">
                    <Image src= { StepThree }  className="porfolio-images img-fluid img-fluid"/>
                </a>
                    {/* <Card.Content>
                    <Card.Header className="steps-card-title"><a href="https://mhr85.github.io/simonGame/" className="footer-one" target="_blank">Simon Game</a></Card.Header>
                    <Card.Description>
                        
                    </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Button.Group>
                            <Button id="switch-button">
                                <Button.Content visible>
                                    <a href="https://github.com/Mhr85/simonGame" target="_blank" className="resume_button">
                                    Github code
                                    </a>
                                </Button.Content>
                            </Button>
                        </Button.Group>
                    </Card.Content> */}
                {/* </Card> */}
            </Fade>
            </Col>

            <Col lg="6" md="6" sm="12" xs="12" className="d-flex justify-content-center mt-5">
            <Fade top cascade duration={1000} delay={2000}>
                {/* <Card className="step-card"> */}
                <a href="http://pethappy.herokuapp.com/" className="footer-one" target="_blank">
                    <Image src= { StepFour } className="porfolio-images img-fluid img-fluid"/>
                </a>
                    {/* <Card.Content>
                    <Card.Header className="steps-card-title"><a href="http://pethappy.herokuapp.com/" className="footer-one" target="_blank">Pet happy</a></Card.Header>
                    <Card.Description>
                    </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Button.Group>
                            <Button id="switch-button">
                                <Button.Content visible>
                                    <a href="https://github.com/estomm01/Project-2-" target="_blank" className="resume_button">
                                    Github code
                                    </a>
                                </Button.Content>
                            </Button>
                        </Button.Group>
                    </Card.Content>
                </Card> */}
            </Fade>
            </Col>
        </Row>
    </Container>
  );
}

export default Steps;