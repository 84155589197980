import React from 'react';
import { Container } from 'reactstrap';
import { Button, Header } from 'semantic-ui-react';
import '../Brand/style.css';

import StrengthsMihir from '../../assets/images/Strengths_MihirPate.pdf';


const MyStrengths = () => {

  return (
    <Container id='strengths'>
      <div className='container-fluid'>
        <div className='text-center mt-5 pt-5'>
          <h1 className='mt-5 resume-font'>My Strengths</h1>
          <p>Click below to print my Gallup Strength Assessment</p>
          <Button id="switch-button" className="mt-5">
              <Button.Content visible>
                <a href={ StrengthsMihir } target="_blank" className="resume_button">
                  Print
                </a>
              </Button.Content>
          </Button>
        </div>
      </div>
      <div className="container bg-white p-5 porfolio-images">
        {/* <iframe className="embed-responsive-item myStrengths" src={ StrengthsMihir } frameborder="0"></iframe> */}
        <h1 className="resume-font">Assessed by Gallup Clifton Strengths Survey</h1>
        <p>Many years of research conducted by The Gallup Organization suggest that the most effective people
        are those who understand their strengths and behaviors. These people are best able to develop
          strategies to meet and exceed the demands of their daily lives, their careers, and their families.</p>

        <hr className="resume-hr" />

        <h1 className="resume-font">1. Responsibility </h1>
        <p>I am comfortable being open and honest about who I am. I feel very good about myself and life in
        general when I keep all the promises, I have made to myself and others. I trust my sense of what
        is right to guide my decision-making and govern my actions. My core values and quality standards
        give me lots of reasons to feel very optimistic about the direction my life is taking.
        Driven by my talents, I usually want to be held accountable for outcomes, especially when what
        I am being asked to do sounds reasonable. I am apt to scrutinize my own and others’ ideas before
        I begin a task. Instinctively, I genuinely feel pleased with myself and life in general when I do
            tasks correctly and behave in accordance with my core values.</p>

        <hr className="resume-hr" />

        <h1 className="resume-font">2. Connectedness </h1>
        <p>I facilitate dialogue between individuals. I create peace within groups and between people by linking
        them to one another. By nature, my words and deeds align with my fundamental values.
        Instinctively, I consider people more important than things. I customarily search for pertinent
        facts and relevant data to reconstruct the chain of events that produced a problem or an opportunity.
        I prefer reasonable explanations. I help people realize they can accomplish more good as a group than
          they can as individuals. I believe there are few coincidences and that every event has a reason.</p>

        <hr className="resume-hr" />

        <h1 className="resume-font">3. Relator</h1>
        <p>By nature, I enjoy the companionship of individuals who tell me what they plan to accomplish in the
        coming weeks, months, or years. Once I know their goals, I can help them reach their objectives.
        Driven by my talents, I assist my teammates by breaking down difficult-to-understand programs,
        rules, processes, concepts, formulas, or policies to their bare essentials. I traditionally do my
        best training after I become well-acquainted with someone. I want to discover each individual’s unique
        work style, goals, motivations, interests, or talents. These insights tell me what suggestions to make
        or what tips to offer during coaching sessions. I am drawn to the process of gaining knowledge and skills.
        I love to build on what I already know. I yearn to improve on what I already can do. Understanding what
          others aim to accomplish in the coming weeks, months, or years can be the beginning of a practical partnership or an enduring friendship.</p>

        <hr className="resume-hr" />

        <h1 className="resume-font">4. Learner </h1>
        <p>I choose to spend each week working toward clearly defined goals. When I have performance targets,
        I am more methodical about gathering information, data, facts, or evidence. Driven by my talents,
        I comprehend what has gone wrong. Eagerly, I uncover facts. Sorting through lots of information rarely
        intimidates me. I welcome the abundance of information. Like a detective, I sort through it and identify
        key pieces of evidence. Following these leads, I bring the big picture into view. Next, I generate schemes
        for solving the problem. Finally, I choose the best option after considering prevailing circumstances,
        available resources, and desired outcomes. Instinctively, I regularly devote myself to gaining knowledge
          and acquiring skills. I read, solve problems, write, rehearse, and practice. I trust myself to meet the challenges. </p>

        <hr className="resume-hr" />

        <h1 className="resume-font">5. Maximizer</h1>
        <p>I seek to transform something strong into something superb. I find that life is quite satisfying when I
        concentrate on using my dominant talents. I move much more quickly toward my goals when I practice doing
        what comes naturally. Driven by my talents, I realize I am very careful and thorough with whatever I do.
        I pay close attention to the smallest details. I can easily view things from someone else’s perspective even
        when I do not share their opinion. I deal with each individual on a personal level, and I focus on strengths
          as a way to stimulate personal and group excellence. </p>
      </div>

    </Container>
  );
}

export default MyStrengths;