import React from 'react';
import Nav from '../components/Nav/Index.jsx';
import PortfolioBrand1 from '../components/Brand/PortfolioBrand1.jsx';
import Footer from '../components/Footer/index.js';
import '../App.css';

function Portfolio () {
  return (
    <div>
      <Nav/>
      <PortfolioBrand1/>
      <Footer />
    </div>
  );
}

export default Portfolio;