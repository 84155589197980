import React from "react";
import MyStrengths from "../components/Strengths/MyStrengths.jsx";
import Nav from "../components/Nav/Index.jsx";
import Footer from "../components/Footer/index.js";
import "../App.css";

function Strengths() {
  return (
    <div>
      <Nav />
      <MyStrengths />
      <Footer />
    </div>
  );
}

export default Strengths;
