import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import { Button, Icon, Image, Header } from 'semantic-ui-react';
import { useSpring, animated } from 'react-spring';
import LightSpeed from 'react-reveal/LightSpeed';
import Jump from 'react-reveal/Jump';
import MortarPestle from '../../assets/images/MP1.png';
import './style.css';

import Ps from '../../assets/images/ps.png';
import Ai from '../../assets/images/ai.png';
import Pr from '../../assets/images/pr.png';
import Au from '../../assets/images/au.png';
import An from '../../assets/images/an.png';
import Spark from '../../assets/images/spark.png';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`

const Brand = () => {
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))
  return (
    <Container id="brand" fluid={true}>
      <Row>
        <Col lg="6" md="6" sm="12" xs="12" className="left-row">
          <Jump left>
            <h1 className="brand-title">Hello! I'm Mihir Patel</h1>
          </Jump>
          <LightSpeed left duration={2000} delay={1000}>
            <h2 className="brand-subtitle font-italic mb-5 mt-0">Developer & Designer</h2>
          </LightSpeed>
            {/* <h6 className="brand-sub-title"><Icon color="teal" name="pills" />We provide FREE DELIVERY in all 5 boroughs of New York</h6>
            <h6 className="brand-sub-title"><Icon color="teal" name="pills" />Blister packaging to improve patients compliance</h6>
            <h6 className="brand-sub-title"><Icon color="teal" name="pills" />We take most insurances!</h6> */}
            <Button id="switch-button">
              <Button.Content visible>
                <Link to="/resume" className="resume_button">
                  Resume
                </Link>
                {/* <a href="https://mhr85.github.io/MihirResume/" target="_blank" className="resume_button">
                  Resume
                </a> */}
              </Button.Content>
            </Button>
            {/* <h1>Skills</h1> */}
            <Row>
            <Col lg="4" md="6" sm="6" xs="6" >
              <Header as="h6" className="skills-p"><Icon className="skills" size="large" name='html5' />HTML5</Header>
              <Header as="h6" className="skills-p"><Icon className="skills" size="large" name='css3 alternate' />CSS3</Header>
              <Header as="h6" className="skills-p"><Icon className="skills" size="large" name='js' />JAVA SCRIPT</Header>
              <Header as="h6" className="skills-p"><Icon className="skills" size="large" name='usd' />JQUERY</Header>
              <Header as="h6" className="skills-p"><Icon className="skills" size="large" name='react' />REACT</Header>
            </Col>
            <Col lg="4" md="6" sm="6" xs="6">
              <Header as="h6" className="skills-p"><Icon className="skills" size="large" name='node' />NODE.JS</Header>
              <Header as="h6" className="skills-p"><Icon className="skills" size="large" name='database' />MYSQL</Header>
              <Header as="h6" className="skills-p"><Icon className="skills" size="large" name='database' />SEQUELIZE</Header>
              <Header as="h6" className="skills-p"><Icon className="skills" size="large" name='database' />MONGODB</Header>
              <Header as="h6" className="skills-p"><Icon className="skills" size="large" name='database' />MONGOOSE</Header>
            </Col>
            </Row>
            <Row>
            <Image className="p-2 m-2" size="tiny" src= { Ps } />
            <Image className="p-2 m-2" size="tiny" src= { Ai } />
            <Image className="p-2 m-2" size="tiny" src= { Pr } />
            <Image className="p-2 m-2" size="tiny" src= { Au } />
            <Image className="p-2 m-2" size="tiny" src= { An } />
            <Image className="p-2 m-2" size="tiny" src= { Spark } />
            </Row>
                
        </Col>
        <Col lg="6" md="6" sm="12" xs="12" className="text-center">
        <div onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
          <animated.div style={{ transform: props.xy.interpolate(trans1) }}>
          {/* <Zoom duration={3000} delay={1000}> */}
          <img src={ MortarPestle } alt="URAC Accredited Specialty Pharmacy" className="brandRx-img img-fluid"></img>
          {/* </Zoom> */}
          </animated.div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Brand;