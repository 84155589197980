import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useSpring, animated } from 'react-spring';
import LightSpeed from 'react-reveal/LightSpeed';
import Jump from 'react-reveal/Jump';
import MortarPestle from '../../assets/images/MP1.png';
import './style.css';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`

const Brand = () => {
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))
  return (
    <Container id="brand-about" fluid={true}>
      <Row>
        <Col lg="6" md="6" sm="12" xs="12" className="text-center">
          <div onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
          <animated.div style={{ transform: props.xy.interpolate(trans1) }}>
          {/* <Zoom duration={3000} delay={1000}> */}
          <img src={ MortarPestle } alt="URAC Accredited Specialty Pharmacy" className="brandRx-img img-fluid"></img>
          {/* </Zoom> */}
          </animated.div>
          </div>
        </Col>
        <Col lg="6" md="6" sm="12" xs="12" className="left-row">
          <Jump left>
            <h1 className="brand-title">Hello! I'm Mihir Patel</h1>
          </Jump>
          <LightSpeed left duration={2000} delay={1000}>
            <h2 className="brand-subtitle font-italic mb-5 mt-0">Developer & Designer</h2>
          </LightSpeed>
          <h2 className="about-me ml-2 mr-2">
          I have a Bachelor’s in Business Administration and a
          Full-stack Coding Bootcamp certification from Rutgers University.
          I am a leader experienced in business operations and a developer specialized in Front-end web development. 
          I have been developing front end projects for over 3 years and working as a Director of Operations for 5 years at a
          medium to large scale organization on Staten Island, NY. 
          I also have basic knowledge and experience working with Adobe applications 
          such as Illustrator, Photoshop, Animate, Premiere Pro and Audition to design and create logos, videos, 
          audio recordings and illustrations. I am constantly learning new things and improving my knowledge to achieve my goals
          and helping others. I love reading books.  My goal 
          in life as Jim Kwik quotes, "Learn, Earn and Return".
          </h2>
        </Col>
      </Row>
    </Container>
  );
}

export default Brand;