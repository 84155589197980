import React from "react";
import BookEntry from "../components/Books/BookEntry.jsx";
import Nav from "../components/Nav/Index.jsx";
import Footer from "../components/Footer/index.js";
import books from "../books.js";
import "../App.css";

function Books() {
  return (
    <div>
      <Nav />
      <BookEntry />
      <Footer />
    </div>
  );
}

export default Books;
