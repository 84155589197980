import React from 'react';
import { Container } from 'reactstrap';
import {  Accordion, Message } from 'semantic-ui-react';
// import faker from 'faker'
import _ from 'lodash'
import './style.css';

// const panels = [
//     {
//       key: 'what-is-dog',
//       title: {
//         content: 'How do you acquire a dog?',
//         icon: 'question',
//       },
//       content: [
//         'A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome',
//         'guest in many households across the world.',
//       ].join(' '),
//     },
//     {
//       key: 'kinds-of-dogs',
//       title: {
//         content: 'What kinds of dogs are there?',
//         icon: 'question',
//     },
//       content: [
//         'There are many breeds of dogs. Each breed varies in size and temperament. Owners often select a breed of dog',
//         'that they find to be compatible with their own lifestyle and desires from a companion.',
//       ].join(' '),
//     },
//     {
//       key: 'acquire-dog',
//       title: {
//       content: 'How do you acquire a dog?',
//       icon: 'question',
//     },
//       content: {
//         content: (
//           <div>
//             <p>
//               Three common ways for a prospective owner to acquire a dog is from
//               pet shops, private owners, or shelters.
//             </p>
//             <p>
//               A pet shop may be the most convenient way to buy a dog. Buying a dog
//               from a private owner allows you to assess the pedigree and
//               upbringing of your dog before choosing to take it home. Lastly,
//               finding your dog from a shelter, helps give a good home to a dog who
//               may not find one so readily.
//             </p>
//           </div>
//         ),
//       },
//     },
// ]

const panels = _.times(3, (i) => ({
  key: `panel-${i}`,
  title: {
    content: <Message info content={'How Does it Work?'} />,
  },
  content: {
    content: (
      <Message
        className='faq-answer'
        info
        header={'Three common ways for a prospective owner to acquire a dog is frompet shops, private owners, or shelters.'}
        content={'A pet shop may be the most convenient way to buy a dog. Buying a dog from a private owner allows you to assess the pedigree upbringing of your dog before choosing to take it home. Lastly,finding your dog from a shelter, helps give a good home to a dog who may not find one so readily.'}
      />
    ),
  },
}))



const Questions = (props) => {
  return (
    <Container className="text-left"  id="questions">
        {/* <h1 className="steps-title font-weight-bold text-center">Frequently Asked Questions</h1> */}
        {/* <Accordion className="questions-content"defaultActiveIndex={0} panels={panels} /> */}
        <Accordion defaultActiveIndex={0} panels={panels} />
    </Container>
  );
}

export default Questions;