import React from 'react';
import { Container } from 'reactstrap';
import './style.css';


const Empty = () => {
  return (
    <Container className="bg-light text-center" fluid={true} id="empty-div">
    </Container>
  );
}

export default Empty;